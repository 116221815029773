<template>
  <transition name="fade">
    <div>

      <div class="card mt-0">
        <div class="card-body">
          <el-steps :active="state.etape" align-center>
            <el-step title="Upload" description="Envoyer la matrice" />
            <el-step title="Vérification" description="Test de cohérence du fichier" />
            <el-step title="Traitement" description="Import des données" />
          </el-steps>
        </div>
      </div>

      <div class="card mt-10">
        <div class="card-body pt-0">

          <div class="card-header" id="kt_activities_header" v-if="state.etape == 1">
              <div class="page-title d-flex  align-items-center  flex-wrap me-3 mb-5 mb-lg-0 ">
                  <h4 style="margin-top: 7px;">
                    <span class="text-gray-700 fs-2" style="margin-top: 8px;">Vos données à importer</span>
                  </h4>
              </div>
              <div class="card-toolbar">

                  <button type="button" class="btn btn-text-icon btn-secondary btn-active-light m-1" style="margin-top: 9px !important; height: 44px;" v-if="state.etape==1" @click="state.etape -= 1">
                    <span class="svg-icon svg-icon-1 svg-icon-dark"><inline-svg src="media/icons/duotune/arrows/arr022.svg" /></span>
                    <span>Retour</span>
                  </button>
                  <button v-if="state.etape==1 && state.totIncoherence==0" type="button" class="btn btn-text-icon btn-primary m-1" style="margin-top: 9px !important; height: 44px;"  @click="importActeurs">
                    <span>Importer la liste</span>
                    <span class="svg-icon svg-icon-1 svg-icon-dark m-5 me-0"><inline-svg src="media/icons/duotune/arrows/arr023.svg" /></span>
                  </button>
              </div>
          </div>


          <el-row class="row-bg mt-10" justify="space-evenly" v-show="state.etape == 0">
            <el-col :span="4"></el-col>
            <el-col :span="16">   
              <el-divider content-position="left"><h3 class="text-gray-700" style="margin-top: 8px;">Exporter votre fichier depuis Arkheïa</h3></el-divider>

              <div class="m-7 fs-6 mt-15">
                Tout d'abord, afin de pouvoir importer les producteurs et fournisseurs présents dans Arkheïa, veuillez suivre la procédure suivante pour exporter le fichier.<br><br>
                Dans Arkheïa, module XXX-YYY-ZZZ, veillez sélectioner Bla bla bla bla bla, puis cliquer sur Bli bli bli bli bli. Enfin il vous suiffra de Blo Blo Blo Blo Blo pour générer le fichier.<br><br>
              </div>

              <el-divider content-position="left" class="mb-15 mt-15"><h3 class="text-gray-700" style="margin-top: 8px;">Importer le fichier</h3></el-divider>

              <el-upload ref="uploadRef" action="#" :on-change="submitUpload" :show-file-list="false" style="width:100%;" :auto-upload="false">
                <div style="border: 1px dashed rgb(90 90 90); border-radius: 4px;" class="pb-10 btn-upload">
                  <div v-show="!state.loaderUpload">
                    <div class="el-upload__text mt-9 mb-5 text-primary fs-6" style="width: 100%;">
                      Veuillez cliquer pour afficher l'explorateur et importer votre fichier.
                    </div>
                    <span class="svg-icon svg-icon-5hx svg-icon-primary m-5">
                      <inline-svg src="media/icons/duotune/files/fil005.svg" />
                    </span>
                  </div>

                  <div v-show="state.loaderUpload" style="width: 100%; height: 100%; text-align: center; padding-top: 100px">
                    <div class="lds-ring"><img src="media/logos/loada.png"/><div></div><div></div><div></div><div></div></div>
                    <div class="fs-4 mb-20 text-primary">Vérification de la cohérence de votre fichier  en cours</div>
                  </div>

                </div>
                  <template #tip>
                    <div class="el-upload__tip mb-12">
                      Attention, seul le fichier fourni par Arkheïa pourra être importée. Veuillez noter que le fichier ne peut être supérieur à 10 MO.
                    </div>
                </template>
              </el-upload>


            </el-col>
            <el-col :span="4"></el-col>
          </el-row>
          <el-row class="row-bg mt-10" justify="space-evenly" v-show="state.etape == 1">

            <div class="mb-5">
              <el-checkbox v-model="form.affNonImportable" size="large" border>Afficher les lignes non importables</el-checkbox>
              <el-checkbox v-model="form.affPersMorale" size="large" border >Afficher les personnes morales</el-checkbox>
              <el-checkbox v-model="form.affPersPhys" size="large" border >Afficher les personnes physiques</el-checkbox>
            </div>

            <el-col :span="24">              
              <el-table :data="state.workbookDisplay" :key="state.workbookDisplay" table-layout="auto" v-if="state.okList" class="fs-6" :row-class-name="tableRowClassName">
                <el-table-column prop="1" label="Code" resizable width="70">
                  <template #default="scope">
                    <div class="badge bg-blue text-white" style="width: 100%;">{{ scope.row[0] }}</div>
                  </template>
                </el-table-column>

                <el-table-column prop="1" label="Type" resizable width="170" sortable >
                  <template #default="scope">
                    <div>
                      {{ returnType(scope.row[1]) }}
                    </div>
                    <div>
                      <b>{{ returnCateg(scope.row[2]) }}</b>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="1" label="Libellés" resizable >
                  <template #default="scope">
                      <div v-if="scope.row[9]" class="text-blue"><b>{{ scope.row[9] }}</b></div>
                      <div v-if="scope.row[4]"><b>{{ scope.row[4] }}</b></div>
                      <div v-if="scope.row[5]"> {{ scope.row[5] }}</div>
                      <div v-if="scope.row[6]">{{ scope.row[6] }}</div>
                      <div v-if="scope.row[7]"> {{ scope.row[7] }}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="1" label="Adresse" resizable width="275">
                  <template #default="scope">
                      <div  v-for="item in  returnMultiAdr(scope.row[10])" :key="item" > 
                        {{ item }}
                      </div>
                      <div>
                          <span>{{ scope.row[11] }}</span>
                          <span> - {{ scope.row[12] }}</span>
                      </div>
                      <div v-if="scope.row[13]"> {{ scope.row[13] }}</div>
                  </template>
                </el-table-column>

                <el-table-column prop="1" label="Coordonnées" resizable width="275">
                  <template #default="scope">
                      <div v-if="scope.row[14]"> {{ scope.row[14] }}</div>
                      <div v-if="scope.row[16]"> {{ scope.row[16] }}</div>
                      <div class="text-blue" v-if="scope.row[17]"> {{ scope.row[17] }}</div>
                  </template>
                </el-table-column>

                <el-table-column prop="1" label="Incohérences" resizable width="175">
                  <template #default="scope">
                      <span class="badge badge-light-danger"  v-for="item in scope.row[29]" :key="item">
                        {{ item }}
                      </span>
                  </template>
                </el-table-column>
                
              </el-table>

            </el-col>
          </el-row>


          <el-row class="row-bg mt-10" justify="space-evenly" v-show="state.etape == 2">
            <el-col :span="24">
                <div style="width: 100%; height: 100%; text-align: center; padding-top: 100px">
                  <div class="lds-ring"><img src="media/logos/loada.png"/><div></div><div></div><div></div><div></div></div>
                  <div class="fs-4 mb-20">Import de vos données en cours</div>
                </div>
            </el-col>
          </el-row>

        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, watch } from "vue";
import * as moment from "moment";
import mAxiosApi from "@/api";
import XLSX from "xlsx";
import { ElNotification } from 'element-plus'
import { useRouter } from "vue-router";

export default defineComponent({
  name: "contrats",
  components: {
    //
  },
 
  emits: ["refreshRouter"],

  setup(props, { emit }) {

    const router = useRouter();
    moment.default.locale("fr");

    const form = reactive({
      affNonImportable : true,
      affPersPhys : true,
      affPersMorale : true,
    });

    const state = reactive({
      etape: 0,
      workbook: [] as any,
      workbookDisplay: [] as any,
      okList : false,
      totIncoherence : 0 as number,
      lConst : [] as any,
      loaderUpload : false,
    });

    const submitUpload = () => {
      state.loaderUpload = true;
      const file1: any = document.querySelector('input[type=file]');
      const file = file1.files[0];
      const reader = new FileReader();
      
      reader.addEventListener("load", function () {
        const data = new Uint8Array(reader.result as ArrayBuffer);
        const zWorkbook = XLSX.read(data, {type: 'array'});
        state.workbook = to_json(zWorkbook)['Sheet1'];
        test_coherence();


      }, false);

      if (file) {
        reader.readAsArrayBuffer(file);
      }

    }

    const excelToDate = (excelDate) => {
      const mDate = new Date(Math.round((excelDate - (25567 + 2)) * 86400 * 1000));
      if (isNaN(mDate.getTime())) {
        return '';
      } 
      const convertedDate = mDate.toISOString().split('T')[0];
      return moment.default(convertedDate).format("DD.MM.YYYY");
    }

    const to_json = function to_json(workbook) {
      var result = {};
      workbook.SheetNames.forEach(function(sheetName) {
        var roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {header:1});
        if(roa.length) result[sheetName] = roa;
      });
      return result;
    };

    const test_coherence = () => {
      state.totIncoherence = 0;
      state.workbook = state.workbook.filter(item => item.length > 0);
      let totIncoherence = 0;

      if (state.workbook[0] != "Export des producteurs et fournisseurs") {
          ElNotification({ title: 'Erreur', message: "L'entête du fichier (Ligne 1) semble incorrecte", type: 'error', })
          state.loaderUpload = false;
          return false;
      }
      delete state.workbook[0];
      delete state.workbook[1];

      state.workbook.forEach(function(item) {
        
        item[29] = [] as any;       
        if ( !parseInt(item[0]) ) item[29].push('Code incorrect');

        if ( returnType(item[1]) == "Type inconnu"  ) item[29].push('Type incorrect');
      
        //if ( item[1] != 2 ) {
          // Les seuls codes pouvant être concernés par les versements sont : 1, 2, 3, 5, 6
          if (item[2] == 4 ) item[29].push('Type non importable');
          if (item[2] == 7 ) item[29].push('Type non importable');
          if (item[2] == 8 ) item[29].push('Type non importable');
          if (item[2] == 9 ) item[29].push('Type non importable');
        //} 
       
      });
      
      state.totIncoherence = totIncoherence;
      state.loaderUpload = false;
      computedWorkbook();
      state.okList = true;
      state.etape = 1;

    };

    watch(form, () => {
      computedWorkbook();
    });


    const computedWorkbook = () => {
      state.okList = false;
      state.workbookDisplay = state.workbook

      if (!form.affNonImportable) {
        state.workbookDisplay = state.workbookDisplay.filter(
          (item: any) => item[29].length == 0);
      }

      if (!form.affPersMorale) {
        state.workbookDisplay = state.workbookDisplay.filter(
          (item: any) => item[1] != 1 && item[1] != 3 );
      }

      if (!form.affPersPhys) {
        state.workbookDisplay = state.workbookDisplay.filter(
          (item: any) => item[1] != 2 );
      }
      state.okList = true;
    }

    const tableRowClassName = ({ row }: {
      row: any
    }) => {
      if (row[29].length > 0) return 'warning-row'
      return ''
    }

    const totDefaut = (mArray) => {
      let test = 0;
      mArray.forEach(function(item) {
        if(item[0]) test += 1;
      });
      return test;
    }

    const importActeurs = async () => {
      state.etape += 1;
      const workbook: any = state.workbook;
      mAxiosApi
        .post("importActeurs", { workbook })
        .then((data) => {

          //console.log("Data", data);

          ElNotification({
            title: 'Succès',
            message: 'Import réalisé avec succès !',
            type: 'success',
          })

          router.push({ name: "admin" });
          
        })
        .catch((err) => {
          console.error(err);
        });
    };

    const returnType = (type) => {
      let mType = ""
      switch (type) {
        case 1:
          mType = "Collectivité"
          break;
        case 2:
          mType = "Personne"
          break;
        case 3:
          mType = "Famille"
          break;
        default:
          mType = "Type inconnu"
      }
      return mType;
    }

    const returnCateg = (type) => {
      let mCateg = ""
      switch (type) {
        case 1:
          mCateg = "Administration"
          break;
        case 2:
          mCateg = "Collectivité territoriale"
          break;
        case 3:
          mCateg = "Etablissement public"
          break;
        case 4:
          mCateg = "Personne physique"
          break;
        case 5:
          mCateg = "Officier public"
          break;
        case 6:
          mCateg = "Entreprise publique"
          break;
        case 7:
          mCateg = "Entreprise privée"
          break;
        case 8:
          mCateg = "Association"
          break;
        case 9:
          mCateg = "Famille"
          break;
        default:
          mCateg = "Catégorie inconnu"
      }
      return mCateg;
    }

    const returnMultiAdr = (row) => {
      if (row) return row.split(" $ ");
      return [];
    }

    onMounted(async () => {
      //state.lConst = await getAxios("/getConstList");
      // console.log('Contructeur', myList);
    });


    return {
      state,
      form,
      submitUpload,
      excelToDate,
      test_coherence,
      totDefaut,
      importActeurs,
      returnType,
      returnCateg,
      returnMultiAdr,
      tableRowClassName,
      computedWorkbook
    };
  
  },
});
export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  // console.log(response.data);
  return response.data;
}
</script>

<style>
.el-table .warning-row {
  background: linear-gradient(45deg, #fff5f89c 12.5%, #fff 12.5%, #fff 37.5%, #fff5f89c 37.5%, #fff5f89c 62.5%, #fff 62.5%, #fff 87.5%, #fff5f89c 87.5%);
  background-size: 10px 10px;
  background-position: 50px 50px;
}

.el-table {
    --el-table-row-hover-background-color: #f5f7faa3 !important;
}

</style>